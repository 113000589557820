import React, { useState } from 'react';
import Modal from '../components/modal.component';
import '../styles/ctabutton.scss';

export default function CTAButton({
	children,
	color,
	inverted,
	annotation,
	openForm,
	floating,
	mail,
	mailaddress
}) {
	const [show, setShow] = useState(false);
	if (!openForm && !mail) {
		return (
			<div className='cta-button'>
				<a
					className={`${color} ${inverted ? 'inverted' : ''}`}
					href='http://app.kuflow.com'
				>
					{children}
				</a>
				{annotation ? <div className='cta-note'>{annotation}</div> : null}
			</div>
		);
	} else if (!openForm && mail) {
		let mailto = mailaddress ?? 'kuflow@kuflow.com'
		mailto = 'mailto:' + mailto
		return (
			<div className='cta-button'>
				<a
					className={`${color} ${inverted ? 'inverted' : ''}`}
					href={mailto}
				>
					{children}
				</a>
				{annotation ? <div className='cta-note'>{annotation}</div> : null}
			</div>
		);
	} else {
		return (
			<div className='cta-button'>
				<Modal onClose={() => setShow(false)} show={show} />
				{floating ? (
					<button className='floating' onClick={() => setShow(true)}>
						<span className='front'>{children}</span>
					</button>
				) : (
					<button
						className={`${color} ${inverted ? 'inverted' : ''}`}
						onClick={() => setShow(true)}
					>
						{children}
					</button>
				)}
				{annotation ? <div className='cta-note'>{annotation}</div> : null}
			</div>
		);
	}
}
