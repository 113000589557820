import React from 'react';
import '../styles/formulario.scss';

export default function FormSpree() {
	return (
		<form
			className='formulario-contacto'
			method='post'
			action='https://formspree.io/f/xrggkpkq'
		>
			<label htmlFor='name'>
				<p style={{ margin: '0' }}>Nombre</p>
				<input type='text' name='_replyto' required />
			</label>
			<label htmlFor='email'>
				<p style={{ margin: '0' }}>Email</p>
				<input type='email' name='_replyto' required />
			</label>

			{/* <label>
				¿Cuándo te viene bien hablar?<textarea name='message'></textarea>
			</label> */}

			<button type='submit'>Enviar</button>
		</form>
	);
}
