import React from 'react';
import LayoutES from '../components/layout.es';
import Seo from '../components/seo.es';
import SolutionsBlockES from '../components/solutionsblock.component.es';
import Benefit from '../components/benefit.component';
import BottomCTA from '../components/bottomCTA.component.es';

import '../styles/solution.scss';

import Zoom from 'react-medium-image-zoom';
import '../styles/imagezoom.css';

export default function SolutionRPA() {
	return (
		<LayoutES>
			<Seo title='Automatización inteligente' />
			<section id='introSolution'>
				<div className='solutionBg solution-rpa'>
					<div id='introSolution-text'>
						<h1>Automatización inteligente</h1>
						<p>¿Lo malo? Solo va a hacer lo que le enseñes a hacer.</p>
						<p>¿Lo bueno? No te pedirá un aumento nunca.</p>
					</div>
				</div>
			</section>
			<section id='benefitsSolution'>
				<Benefit posImage='R' imageUrl='/images/kuflow_critical-automation.png'>
					<h2>Automatiza todos los procesos críticos</h2>
					<p>KuFlow va a ser tu mejor fichaje:</p>
					<ol>
						<li>
							<strong>Aprende rápido</strong> cualquier tarea definida.
						</li>
						<li>
							Las <strong>ejecuta más rápido aún</strong>.
						</li>
						<li>
							<strong>Trabaja 24h</strong>.
						</li>
						<li>
							<strong>Puedes ver todos los bots en marcha</strong>, sus estados
							y sus estadísticas.
						</li>
					</ol>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_processlist_01_ES.png'
							width='280'
							style={{ margin: '0 auto' }}
							alt='Lista de procesos'
						/>
					</Zoom>
				</Benefit>
				<Benefit posImage='L' imageUrl='/images/kuflow_handshake.png'>
					<h2>Se lleva bien con lo que ya tienes</h2>
					<p>Se trata de ayudar, no de poner palos en las ruedas.</p>
					<p>
						Por eso <strong>KuFlow puede conectarse con lo que sea</strong>.
					</p>
					<p>
						¿Quieres conectarlo con una aplicación que tiene{' '}
						<strong>API</strong>? Sin problema, entre ellos se van a entender.
					</p>
					<p>
						¿No tiene API? Pues no pasa nada,{' '}
						<strong>KuFlow también sabe leer la pantalla y hacer clic</strong>{' '}
						donde le enseñes.
					</p>
				</Benefit>
				<Benefit posImage='R' imageUrl='/images/kuflow_world-class-rpa.png'>
					<h2>RPA sin cruzar los dedos</h2>
					<p>
						KuFlow está diseñado para ser <strong>resiliente</strong>. Esto
						significa:
					</p>
					<ol>
						<li>
							Se encarga de los <strong>retries</strong>.
						</li>
						<li>
							Gestiona los <strong>timeouts</strong> en las conexiones.
						</li>
						<li>
							Se maneja perfectamente en <strong>procesos asíncronos</strong>.
						</li>
						<li>
							<strong>Mantiene los estados de las peticiones</strong> para que
							no se le escape nada.
						</li>
					</ol>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_RPAdetails_01_ES.png'
							width='280'
							style={{ margin: '0 auto' }}
							alt='RPA reintentándolo'
						/>
					</Zoom>
				</Benefit>
				<Benefit posImage='L' imageUrl='/images/kuflow_devs.png'>
					<h2>¿No-code? No, gracias</h2>
					<p>No somos mucho de flechitas ni cajas. Lo justito.</p>
					<p>Tampoco somos demasiado de macros ni de low-code.</p>
					<p>
						<strong>
							Nos gusta que los pasos estén bien definidos, que las variables
							sean verbose y que si algo falla, sepamos por qué.
						</strong>
					</p>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_code01.png'
							width='280'
							style={{ margin: '0 auto' }}
							alt='Código de flujo de trabajo'
						/>
					</Zoom>
				</Benefit>
				<Benefit posImage='R' imageUrl='/images/kuflow_happy-customer.png'>
					<h2>Tus clientes lo agradecerán</h2>
					<p>
						Solo <strong>con automatizar muy poquitas cosas</strong> (tíckets,
						primeros contactos, recordatorios, avisos de pago, etc.) la
						experiencia de tu cliente mejorará enormemente.
					</p>
					<p>
						Recuerda, <i>under promise and over deliver</i>.
					</p>
				</Benefit>
			</section>
			<SolutionsBlockES />
			<BottomCTA />
		</LayoutES>
	);
}
