import React from 'react';
import { Link } from 'gatsby';
import '../styles/benefit.scss';

export default function Benefit(props) {
	return (
		<div
			className='benefit'
			data-sal='flip-up'
			data-sal-delay='200'
			data-sal-easing='cubic-bezier(0.25, 0.1, 0, 2.05)'
		>
			<div
				className='benefit_column'
				style={{
					order: `${props.posImage === 'R' ? 1 : 0}`,
				}}
			>
				<img src={props.imageUrl} alt='Beneficio' />
			</div>
			<div className='benefit_column benefit_text'>
				{props.children}
				<p
					className='benefit_column_link'
					style={{
						display: `${!props.linkTo ? 'none' : 'auto'}`,
					}}
				>
					<Link to={props.linkTo} className='link--animated'>
						{props.linkText} 🡲
					</Link>
				</p>
			</div>
		</div>
	);
}
