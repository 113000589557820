import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import CTAButton from './ctabutton.component';
import '../styles/bottomCTA.scss';

export default function BottomCTA() {
	return (
		<div
			className='bCTA_columns'
			data-sal='flip-up'
			data-sal-delay='200'
			data-sal-easing='cubic-bezier(0.25, 0.1, 0, 2.05)'
		>
			<div className='bCTA_column bCTA_text'>
				<h2>¿Empezamos?</h2>
				<p>
					<strong>
						En 30 minutos puedes tener el primer proceso en marcha
					</strong>{' '}
					y empezar a saborear lo que significa tener una empresa
					hiperautomatizada.
				</p>
				<p>
					También puede ser que <strong>necesites ayuda para arrancar</strong>,
					en ese caso debes saber que prestamos cabezas y manos.
				</p>

				<CTAButton color='lblue'>COMENZAR GRATIS</CTAButton>
			</div>
			<div className='bCTA_column bCTA_image'>
				<StaticImage
					src='../static/images/kuflow_mano_servicios.png'
					alt='Te echamos una mano'
					height={250}
				/>
			</div>
		</div>
	);
}
