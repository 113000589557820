import React from 'react';
import FormSpree from './formspree.component';
import '../styles/modal.scss';

const Modal = (props) => {
	return (
		<div
			className={`${props.show ? 'show' : ''} modal-form`}
			onClick={props.onClose}
		>
			<div className='modal-content' onClick={(e) => e.stopPropagation()}>
				<div className='modal-header'>
					<p className='modal-title'>Contacta</p>
				</div>
				<FormSpree />
				<div className='modal-footer'>
					{/* <button className='button' onClick={props.onClose}>
						Cerrar
					</button> */}
				</div>
			</div>
		</div>
	);
};

export default Modal;
